import { validateInputFormat } from "../../utils";

const message = "Ya bu son yada bundan sonra vergül daxil etməlisiz";
export const handleFennChange = (e,setFenns) => {
    const value = e.target.value;
    setFenns(value);
    // if (validateInputFormat(value)) {
    //     setFenns(value); // or set it to the state you want
    // } else {
    //     alert("");
    // }
}
export const handleSectorsChange = (e, setSectors) => {
    const value = e.target.value;
    setSectors(value);
    // if (validateInputFormat(value)) {
    //     setSectors(value); // or set it to the state you want
    // } else {
    //     alert("Please enter only letters A-Z or numbers separated by commas (e.g., A,B,C or 1,2,3)");
    // }
}
export const handleCinsChange = (e, setCins) => {
    const value = e.target.value;
    setCins(value);
    // if (validateInputFormat(value)) {
    //     setCins(value); // or set it to the state you want
    // } else {
    //     alert("Please enter only letters A-Z or numbers separated by commas (e.g., A,B,C or 1,2,3)");
    // }
}

export const handlePercentageChange = (e, setPercentage) => {
    const value = e.target.value;
    setPercentage(value);
    // if (validateInputFormat(value)) {
    //     setCins(value); // or set it to the state you want
    // } else {
    //     alert("Please enter only letters A-Z or numbers separated by commas (e.g., A,B,C or 1,2,3)");
    // }
}