import React, { useState, useEffect } from 'react';

const predefinedUsers = [
    { email: 'karim@gmail.com', password: 'karim' },
    { email: 'elnare@gmail.com', password: 'elnare'}
];

function LoginApp() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        // Check if the user is already logged in
        const loggedInStatus = localStorage.getItem('isLoggedIn');
        if (loggedInStatus === 'true') {
            setIsLoggedIn(true);
        }
    }, []);

    const handleLogin = () => {
        const user = predefinedUsers.find(
            (user) => user.email === email && user.password === password
        );

        if (user) {
            // Successful login
            setIsLoggedIn(true);
            localStorage.setItem('isLoggedIn', 'true');
            setError('');
            window.location.reload();
        } else {
            // Failed login
            setError('Invalid email or password');
            localStorage.setItem('isLoggedIn', 'false');
        }
    };

    const handleLogout = () => {
        setIsLoggedIn(false);
        localStorage.setItem('isLoggedIn', 'false');
    };

    return (
        <div style={styles.container}>
            <div style={styles.form}>
                <h2 style={styles.header}>{isLoggedIn ? 'Welcome Back!' : 'Login'}</h2>
                {isLoggedIn ? (
                    <div>
                        <p style={styles.successMessage}>You are logged in.</p>
                        <button onClick={handleLogout} style={styles.logoutButton}>
                            Logout
                        </button>
                    </div>
                ) : (
                    <div>
                        <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            style={styles.input}
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            style={styles.input}
                        />
                        <button onClick={handleLogin} style={styles.loginButton}>
                            Login
                        </button>
                        {error && <p style={styles.error}>{error}</p>}
                    </div>
                )}
            </div>
        </div>
    );
}

// Styles for the component
const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#f4f4f9',
        fontFamily: 'Arial, sans-serif',
    },
    form: {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        width: '100%',
        maxWidth: '400px',
    },
    header: {
        textAlign: 'center',
        color: '#333',
    },
    input: {
        display: 'block',
        width: '100%',
        padding: '10px',
        margin: '10px 0',
        borderRadius: '5px',
        border: '1px solid #ccc',
        fontSize: '16px',
    },
    loginButton: {
        display: 'block',
        width: '100%',
        padding: '12px',
        marginTop: '10px',
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        fontSize: '16px',
        cursor: 'pointer',
    },
    logoutButton: {
        padding: '12px',
        marginTop: '10px',
        backgroundColor: '#e74c3c',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        fontSize: '16px',
        cursor: 'pointer',
    },
    error: {
        color: 'red',
        textAlign: 'center',
        marginTop: '10px',
    },
    successMessage: {
        color: 'green',
        textAlign: 'center',
    },
};

export default LoginApp;
