import React, { useState, useEffect } from 'react';
import './App.css';
import TextFileReader from './components/FileReader/TextFileReader';
import LoginApp from './components/Login/LoginApp';
import {fileContentToArray} from "./utils";
function App() {
  const [lines, setLines] = useState([]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'text/plain') {
        const reader = new FileReader();

        reader.onload = (e) => {
            const text = e.target.result;
            const lineArray = fileContentToArray(text);
            setLines(lineArray);
        };

        reader.readAsText(file);
    } else {
        alert("Please upload a valid .txt file");
    }
};
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Check if the user is logged in from localStorage
    const loggedInStatus = localStorage.getItem('isLoggedIn');
    if (loggedInStatus === 'true') {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.setItem('isLoggedIn', 'false');
};
  return (
    <div className="App">
      {!isLoggedIn ? (
        <LoginApp />
      ) : (
        <>
          <h1>Hello Arti... <button onClick={handleLogout}>Hesabdan Çıx</button></h1>
          <TextFileReader handleFileUpload={handleFileUpload} lines={lines} />
        </>
      )}
    </div>
  );
}

export default App;
