import React, { useState, useEffect } from 'react';
import { detectAllSlidingFromTxt, findIncorrectClass, detectSameUtis, findIncorrectSchools, fileContentToArray, findUtisFromLine, textToArray, validateInputFormat, findAllPercentageOfLines } from '../../utils';
import './TextFileReader.css';
import { handleCinsChange, handleFennChange, handlePercentageChange, handleSectorsChange } from './helper';

function TextFileReader({ handleFileUpload, lines }) {
  const [fenss, setFenns] = useState("");
  const [sectors, setSectors] = useState("");
  const [cinss, setCins] = useState("");
  const [selectedTab, setSelectedTab] = useState(1); // State to track the selected tab

  const [inCorrectClass, setINcorrectClass] = useState([]);
  const [incorrectSchools, setIncorrectSchools] = useState([]);
  const [slidings, setSlidings] = useState([]);
  const [sameUtises, setSameUtises] = useState([]);
  const [linePercentages, setLinePercentages] = useState([]);

  const [percentage, setPercentage] = useState(70);
  const [calculated, setCalculated] = useState(false); // State to trigger calculation
  const [loading, setLoading] = useState(false); // State for loading indicator

  useEffect(() => {
    if (selectedTab === 3 && inCorrectClass.length === 0) {
      setINcorrectClass(findIncorrectClass(lines));
    }
  }, [lines, selectedTab]);

  useEffect(() => {
    if (selectedTab === 2) {
      setSlidings(detectAllSlidingFromTxt(lines, textToArray(fenss), textToArray(sectors), textToArray(cinss)));
    }
  }, [lines, fenss, sectors, cinss, selectedTab]);

  useEffect(() => {
    if (selectedTab === 4 && sameUtises.length === 0) {
      setSameUtises(detectSameUtis(lines));
    }
  }, [lines, selectedTab]);

  useEffect(() => {
    if (selectedTab === 5 && incorrectSchools.length === 0) {
      setIncorrectSchools(findIncorrectSchools(lines));
    }
  }, [lines, selectedTab]);

  // Calculate when the button is clicked or when the percentage changes
  useEffect(() => {
    if (selectedTab === 6 && calculated) {
      setLoading(true); // Start loading
      setLinePercentages(findAllPercentageOfLines(lines, percentage));
      setLoading(false); // End loading
      setCalculated(false); // Reset after calculation
    }
  }, [lines, selectedTab, percentage, calculated]);

  const handleCalculateClick = () => {
    setCalculated(true); // Trigger calculation on button click
  };

  // Tab content rendering logic
  const renderTabContent = () => {
    switch (selectedTab) {
      case 1:
        return (
          <div className="tab-content">
            <div className="input-section">
              <input
                type="file"
                accept=".txt"
                onChange={handleFileUpload}
                className="file-input"
              />
            </div>
            <h3>Yüklədiyiniz fayl olduğu kimi</h3>
            <ol className="output-list">
              {lines.map((line, index) => (
                <li key={index}><pre>{index + 1}.{line}</pre></li>
              ))}
            </ol>
          </div>
        );
      case 2:
        return (
          <div className="tab-content">
            <div className="input-section">
              <input
                type="text"
                value={cinss}
                placeholder="Cins daxil et (e.g., K,Q)"
                onChange={(e) => handleCinsChange(e, setCins)}
                className="input-field"
              />
              <input
                type="text"
                value={fenss}
                placeholder="Fənn daxil et (e.g., A,B,K,I,F)"
                onChange={(e) => handleFennChange(e, setFenns)}
                className="input-field"
              />
              <input
                type="text"
                value={sectors}
                placeholder="Bölmə daxil et (e.g., A,R)"
                onChange={(e) => handleSectorsChange(e, setSectors)}
                className="input-field"
              />
            </div>
            <h3>Tapılan Sürüşmələr</h3>
            <ol className="output-list">
              {slidings?.map((utis, index) => (
                <li key={index}>{index + 1}. {utis}</li>
              ))}
            </ol>
          </div>
        );
      case 3:
        return (
          <div className="tab-content">
            <h3>Düzgün olmayan siniflər</h3>
            <ol className="output-list">
              {inCorrectClass?.map((utis, index) => (
                <li key={index}>{index + 1}. {utis}</li>
              ))}
            </ol>
          </div>
        );
      case 4:
        return (
          <div className="tab-content">
            <h3>Təkrarlanan Utislər</h3>
            <ol className="output-list">
              {sameUtises?.map((utis, index) => (
                <li key={index}>{index + 1}. {utis}</li>
              ))}
            </ol>
          </div>
        );
      case 5:
        return (
          <div className="tab-content">
            <h3>Düzgün olmayan siniflər</h3>
            <ol className="output-list">
              {incorrectSchools?.map((utis, index) => (
                <li key={index}>{index + 1}. {utis}</li>
              ))}
            </ol>
          </div>
        );
      case 6:
        return (
          <div className="tab-content">
            <div className="input-section">
              <input
                type="number"
                value={percentage}
                min={0}
                max={100}
                placeholder="Min oxşarlıq faizini daxil edin (e.g 70)"
                onChange={(e) => handlePercentageChange(e, setPercentage)}
                className="input-field"
              />
              <button onClick={handleCalculateClick} className="calculate-button">Hesabla</button>
            </div>
            {loading ? (
              <p className="loading-message">Calculating, please wait...</p>
            ) : (
              <>
                <h3>Oxşarlıq dərəcəsi</h3>
                <ol className="output-list">
                  {linePercentages?.map((utis, index) => (
                    <li key={index}>{index + 1}. {utis}</li>
                  ))}
                </ol>
              </>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="text-file-reader">
      <h2>Text File Analyzer</h2>
      
      {/* Tabs Section */}
      <div className="tabs">
        <button
          onClick={() => setSelectedTab(1)}
          className={selectedTab === 1 ? 'active-tab' : ''}
        >
          Əsas Fayl
        </button>
        <button
          onClick={() => setSelectedTab(2)}
          className={selectedTab === 2 ? 'active-tab' : ''}
        >
          Sürüşmələr
        </button>
        <button
          onClick={() => setSelectedTab(3)}
          className={selectedTab === 3 ? 'active-tab' : ''}
        >
          Düzgün olmayan siniflər
        </button>
        <button
          onClick={() => setSelectedTab(4)}
          className={selectedTab === 4 ? 'active-tab' : ''}
        >
          Təkrarlanan Utislər
        </button>
        <button
          onClick={() => setSelectedTab(5)}
          className={selectedTab === 5 ? 'active-tab' : ''}
        >
          Yalnış məktəblər
        </button>
        <button
          onClick={() => setSelectedTab(6)}
          className={selectedTab === 6 ? 'active-tab' : ''}
        >
          Oxşarlıq dərəcəsi
        </button>
      </div>

      {/* Tab Content Section */}
      <div className="tab-content-section">
        {renderTabContent()}
      </div>
    </div>
  );
}

export default TextFileReader;
